<template>
  <section class="sectionAboutMe bckg-par">
    <div class="main-wrapper">
      <div class="container aboutMe">
        <div
          class="row justify-content-center justify-content-lg-between background mt-5"
        >
          <div
            class="col-12 col-lg-5 d-flex flex-column justify-content-center align-items-center align-items-lg-start"
          >
            <h1>Kamil Knap</h1>
            <h2 class="mt-3 developer">Full-stack developer</h2>
            <VueWriter :array="arr" :typeSpeed="100" />
            <a class="mt-3 tag-more" href="#aboutMe">{{ $t("home.invite") }}</a>
          </div>
          <div class="col-12 col-lg-5 d-flex justify-content-center">
            <img
              style="width: 400px; height: auto"
              src="../assets/kamil_knap.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="aboutMe" class="py-5">
    <AboutMeView />
  </section>
  <div class="bckg-par second d-flex align-items-center justify-content-center">
    <div class="container d-contents">
      <p class="text-center display-6 par-title">
        <strong>{{ $t("technologies.tailoredTechnologies") }}</strong>
      </p>
    </div>
  </div>
  <section id="experience">
    <ExperienceComponentVue />
  </section>

  <section id="projects" class="bckg-par third">
    <div class="container">
      <sliderImageVue />
    </div>
  </section>

  <section id="contact" class="contact">
    <div class="container">
      <contactComponent />
    </div>
  </section>
</template>
  
<script>
import contactComponent from "@/components/contactComponent.vue";
import sliderImageVue from "@/components/sliderImage.vue";
import VueWriter from "vue-writer";
import ExperienceComponentVue from "@/components/ExperienceComponent.vue";
import AboutMeView from "@/components/AboutMeComponent.vue";

export default {
  name: "HomePage",
  components: {
    contactComponent,
    ExperienceComponentVue,
    AboutMeView,
    sliderImageVue,
    VueWriter,
  },
  data() {
    return {
      // arr: ["- prawdopodobnie najlepszy na śląsku."],
    };
  },
  computed: {
    arr: {
      get() {
        return [this.$t("home.writerDesc")];
      },
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    goToRoute() {
      this.$router.push("/calculator-hdd");
    },
    getImgUrl(image) {
      return require("../assets/" + image);
    },
  },
};
</script>

<style scoped>
</style>
  