import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomePageView.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/calculator-hdd',
    name: 'clcHdd',
    component: () => import(/* webpackChunkName: "clcHdd" */ '../views/ProjectClcHdd.vue')
  },
  {
    path: '/abax-2-predict',
    name: 'abax2Predict',
    component: () => import(/* webpackChunkName: "abax2Predict" */ '../views/ProjectAbax2Predict.vue')
  },
  { 
    path: '/:pathMatch(.*)*', 
    redirect: () => {
      return { name: 'home', query: {}, params: {} }
    } 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    if (!savedPosition && !to.hash) {
      return { top: 0 }
    }
    else if(to.hash){
      return {
        // could also be
        // el: document.getElementById('main'),
        el: to.hash,
        // 10px above the element
        top: 60,
      }
    }
    else {
      return savedPosition
    }
  },
})

router.beforeEach((to, from, next) => {
  AOS.init(); // Initialize AOS
  next();
});

export default router
