<template>
  <Splide :options="optionsToSlider" aria-label="My Favorite Images" class="splide bt-100">
    <SplideSlide v-for="project in projects" :key="project.id">
      <div class="one-project">
        <div class="project-overlay">
          <div class="row justify-content-center">
            <p class="project-title">
              {{ project.title }}
            </p>
            <div class="d-flex flex-row justify-content-center project-details">
              <a class="px-2" :href="project.view">
                <mdicon name="open-in-new" size="30" />
              </a>
              <a v-if="project.github" class="px-2" :href="project.github">
                <mdicon name="git" size="30" />
              </a>
            </div>
          </div>
        </div>
        <img class="project-image" :src="getImgUrl(project.image)" />
        <div class="project-technologies">
          <mdicon v-for="technology in project.technologies" :key="technology" :name="technology" size="20" />
        </div>
      </div>
    </SplideSlide>
  </Splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/sea-green';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      projects: [
        {
          id: 1,
          title: "Calculator HDD",
          technologies: [
            'language-csharp',
          ],
          view: "/calculator-hdd",
          github: "https://github.com/Kamil14210/CalculatorHDD",
          image: "dalle-clc_hdd.jpg"
        },
        {
          id: 2,
          title: "emiter.net.pl",
          technologies: [
            'jquery', "language-php", "sass", "language-javascript"
          ],
          db: 'mysql.png',
          view: "https://emiter.net.pl/",
          image: "emiternet_pl.jpg"
        },
        {
          id: 3,
          title: "Abax2 Predict",
          technologies: [
            'language-csharp', "language-python"
          ],
          db: 'mysql.png',
          view: "/abax-2-predict",
          github: "https://github.com/Kamil14210/Abax2_Predict",
          image: "dalle-abax2_predict.jpg"
        },

      ],
    };
  },

  computed: {
    optionsToSlider: {
      get() {
        if (this.windowWidth < 768) {
          return { fixedWidth: false, fixedHeight: false, gap: false, type: 'loop', autoplay: true, interval: 3000, speed: 1500 }
        }
        else {
          return { fixedWidth: '25rem', fixedHeight: '25rem', gap: '2rem', type: 'loop', autoplay: true, interval: 3000, speed: 1500 }
        }
      }
    },
  },
  components: {
    Splide,
    SplideSlide
  },
  methods: {
    getImgUrl(image) {
      return require("../assets/" + image);
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }
};
</script>