<template>
  <div class="row justify-content-center justify-content-md-between py-4">
    <div
      class="col-12 col-md-6 contact-info p-3 py-md-5"
      data-aos="fade-up-right"
      data-aos-anchor-placement="center-bottom"
      data-aos-once="true"
    >
      <h3>{{ $t("menu.contact") }}</h3>
      <p class="mt-3">
        {{ $t("contact.info") }}
      </p>
      <div class="mt-5">
        <div class="row align-items-center">
          <div class="col-2 col-md-1">
            <mdicon name="cellphone-basic" size="30" />
          </div>
          <div class="col-6">
            <a href="tel:+48736862509">+48 736 862 509</a>
          </div>
        </div>
        <div class="row align-items-center mt-3">
          <div class="col-2 col-md-1">
            <mdicon name="email-arrow-right-outline" size="30" />
          </div>
          <div class="col-6">
            <a href="mailto:kontakt@kamilknap.pl">kontakt@kamilknap.pl</a>
          </div>
        </div>
      </div>
      <div class="row align-items-center mt-3">
        <div class="col-2 col-md-1">
          <mdicon name="navigation-variant-outline" size="30" />
        </div>
        <div class="col-8">
          <p class="m-0">{{ $t("contact.location") }}, Katowice</p>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-6 py-5"
      data-aos="fade-up-left"
      data-aos-anchor-placement="center-bottom"
      data-aos-once="true"
    >
      <div class="d-flex flex-column">
        <label>{{ $t("contact.name") }}</label>
        <input
          id="firstname"
          :class="{ errorClass: errors.name }"
          :placeholder="$t('contact.enterName')"
          type="text"
          name="user_name"
          v-model="name"
          @change="nameIsValid"
        />
        <span v-if="errors.name" class="errorInfo">{{
          $t("contact.requireField")
        }}</span>
      </div>
      <div class="d-flex flex-column mt-3">
        <label>Email</label>
        <input
          :class="{ errorClass: errors.email }"
          :placeholder="$t('contact.mail')"
          type="text"
          name="user_email"
          v-model="email"
          @change="emailIsValid"
        />
        <span v-if="errors.email != null" class="errorInfo">{{
          $t("contact.requireField")
        }}</span>
      </div>
      <div class="d-flex flex-column mt-3">
        <label>{{ $t("contact.message") }}</label>
        <textarea
          id="message"
          name="message"
          :placeholder="$t('contact.messageContent')"
          rows="5"
          class="no-resize"
          v-model="message"
          @change="messageIsValid"
        ></textarea>
        <span v-if="errors.message != null" class="errorInfo">{{
          errors.message
        }}</span>
      </div>
      <div class="d-flex mt-2">
        <button @click="sendEmail">{{ $t("contact.send") }}</button>
      </div>
      <p class="sendedEmail" v-if="sendedEmail">{{ $t('contact.successEmail')}}</p>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  data() {
    return {
      name: null,
      email: "",
      message: null,
      errors: {
        name: null,
        email: null,
        message: null,
      },
      sendedEmail: false,
    };
  },
  components: {},
  computed: {
    checkEmail: {
      get() {
        if (this.email.indexOf("@") > 0) {
          return true;
        }
        return false;
      },
    },
  },
  methods: {
    async sendEmail() {
      try {
        let data = {
          service_id: process.env.VUE_APP_SERVICE_ID,
          template_id: process.env.VUE_APP_TEMPLATE_ID,
          user_id: process.env.VUE_APP_USER_ID,
          template_params: {
            to_name: this.name,
            from_name: this.email,
            message: this.message,
          },
        };

        if (this.name && this.checkEmail && this.message) {
          const sended = await axios.post(
            "https://api.emailjs.com/api/v1.0/email/send",
            data
          );

          if (sended) {
            this.afterSendMail();
          } else {
            alert(
              this.$t('contact.errorEmail')
            );
          }
          // alert("poszło");
        } else {
          this.nameIsValid();
          this.messageIsValid();
          this.emailIsValid();
        }
      } catch (error) {
        alert(
              this.$t('contact.errorEmail')
            );
        // console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
    afterSendMail() {
      this.sendedEmail = true;
      setTimeout(() => {
        this.sendedEmail = false;
      }, "4500");
    },
    emailIsValid() {
      this.errors.email = null;
      if (!this.email || this.email.indexOf("@") <= 0) {
        this.errors.email = this.$t('contact.requiredEmail');
      }
    },

    messageIsValid() {
      this.errors.message = null;
      if (!this.message || this.message.length < 5) {
        this.errors.message = this.$t("contact.minMessageContent");
      }
    },
    nameIsValid() {
      this.errors.name = null;
      if (!this.name || this.name.length < 2) {
        this.errors.name = this.$t('contact.requireField');
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.sendedEmail {
  margin: 5px;
  color: greenyellow;
  font-size: 20px;
}
</style>