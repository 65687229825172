<template>
  <div class="container py-1 py-md-5">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2 class="mb-4">{{ $t('aboutMe.somethingAbout')}}</h2>
        <p>
          {{ $t('aboutMe.descRemember')}}
        </p>
        <p>
          {{ $t('aboutMe.iAmProgrammer')}}<br />
          {{ $t('aboutMe.emiternet')}}
        </p>
      </div>
      <div class="col-12 col-md-6 d-flex align-items-center">
        <img
          style="width: 100%; height: auto"
          src="../assets/kkn_company.jpg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>