<template>
  <div class="container my-experience">
    <div class="row justify-content-center py-4">
      <div
        class="col-12 col-md-6 d-flex flex-row align-items-center justify-content-center justify-content-md-start p-4 py-md-2"
      >
        <div class="">
          <p class="technologies-title">{{ $t('technologies.mainThemes')}}</p>
          <p class="technologies-details my-0">VueJs - front-end</p>
          <p class="technologies-details my-0">NodeJs - back-end</p>
          <p class="technologies-details my-0">MongoDB, MySQL - {{ $t("technologies.dataBases")}}</p>
          <div class="technologies-title d-flex flex-row align-items-center">
            <div class="line"></div>
            {{ $t('technologies.andOther')}}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row technologies">
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img
              height="45 px"
              width="auto"
              src="../assets/JavaScript_logo.svg"
            />
            <!-- <p>Java Script</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/c_sharp.png" />
            <!-- <p>C#</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/php_logo.png" />
            <!-- <p>PHP</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="auto" width="45 px" src="../assets/logo.png" />
            <!-- <p>Vue.js</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/jquery_logo.png" />
            <!-- <p>jQuery</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img
              height="45px"
              width="auto"
              src="../assets/bootstrap_logo.png"
            />
            <!-- <p>Bootstrap</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/html_5.png" />
            <!-- <p>HTML5</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/sass_logo.png" />
            <!-- <p>SASS</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/css3_logo.png" />
            <!-- <p>CSS</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/nodejs.png" />
            <!-- <p>Node.js</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="auto" width="125px" src="../assets/mongodb_logo.png" />
            <!-- <p>MongoDB</p> -->
          </div>
          <div
            class="col-4 d-flex align-items-center justify-content-center py-5 px-4"
          >
            <img height="45px" width="auto" src="../assets/mysql_logo.png" />
            <!-- <p>MySQL</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
/* Do obrazków dodać opisy i czy trzeba coś żeby pokazać źródło strony? */
</style>