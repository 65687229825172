import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "./style/style.scss";
import "@vueform/multiselect/themes/default.css";
import mdiVue from "mdi-vue/v3";
import * as mdijs from "@mdi/js";
import { createI18n } from "vue-i18n";
import AOS from 'aos'
import 'aos/dist/aos.css'
import vClickOutside from "click-outside-vue3"

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function languageOfBrowser() {
  let localLang = localStorage.getItem("language");
  if (localLang && localLang.toLowerCase() == 'pl') {
    return 'pl'
  }
  else if (localLang && localLang.toLowerCase() == 'en') {
    return 'en'
  }
  else {
    if (navigator.language == "pl-PL") {
      return "pl";
    } else {
      return "en";
    }
  }
}
// window.addEventListener("scroll", () => {
//   let header = document.querySelector(".header-background")
// 	if (window.scrollY > 70) {
// 		header.classList.add("otherr");
// 	} else {
// 		header.classList.remove("otherr");
// 	}
// });

const i18n = createI18n({
  locale: languageOfBrowser(), // set locale
  // allowComposition: true,
  fallbackLocale: navigator.language || "pl", // set fallback locale
  messages: loadLocaleMessages(),
  interpolation: {
    escapeValue: false,
  }
});

createApp(App)
  .use(mdiVue, {
    icons: mdijs,
  })
  .use(router)
  .use(i18n)
  .use(AOS)
  .use(vClickOutside)
  .mount("#app");
