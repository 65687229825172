<template>
  <header class="header-background">
    <nav>
      <div class="navbar hamburger-menu"  v-click-outside="closeMenu">
        <div class="container nav-container">
          <input class="checkbox" type="checkbox" name="" id="primaryBurgerMenu" />
          <div class="hamburger-lines">
            <span class="line line1"></span>
            <span class="line line2"></span>
            <span class="line line3"></span>
          </div>
          <div class="menu-items">
            <ul>
              <li @click="closeMenu"><a href='/#aboutMe'>{{ $t('menu.aboutMe') }}</a></li>
              <li @click="closeMenu"><a href="/#experience">{{ $t('menu.technologies') }}</a></li>
              <li class="listOfProjects"><a  @click="closeMenu" href="/#projects">{{ $t('menu.projects') }}</a>
                <mdicon @click="showProjects" class="fillArrow"
                  :name="openProjects ? 'chevron-down' : 'chevron-right'" size="35" />
              </li>
              <div @click="openProjects" class="insideProjects">
                <a href="/calculator-hdd" class="f18">Calculator HDD</a>
                <a href="/abax-2-predict" class="f18">Abax2 Predict</a>
                <a href="https://emiter.net.pl/" target="_blank" class="f18">emiter.net.pl</a>
              </div>
              <li><a href="/#contact">{{ $t('menu.contact') }}</a></li>
            </ul>
            <div class="d-flex flex-row align-items-center justify-content-center lang-switch">
              <a class="burger-lang" v-if="$i18n.locale !== 'pl'" v-on:click="changeLanguage('pl')">PL</a>
              <strong v-if="$i18n.locale === 'pl'">PL</strong>
              &nbsp;|&nbsp;
              <a class="burger-lang" v-if="$i18n.locale !== 'en'" v-on:click="changeLanguage('en')">EN</a>
              <strong v-if="$i18n.locale === 'en'">EN</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-3 main-logo mr-0">
            <router-link class="d-flex flex-row align-items-center" to="/">
              <img style="width: 133px; padding: 5px" src="./assets/logo_kkn_black.png" />
            </router-link>
          </div>
          <div class="primary-menu col d-flex flex-row align-items-center justify-content-end">
            <router-link to="/#aboutMe" class="a m-3">{{ $t('menu.aboutMe') }}</router-link>
            <router-link to="/#experience" class="a m-3">{{ $t('menu.technologies') }}</router-link>
            <router-link to="/#projects" class="a m-3">{{ $t('menu.projects') }}</router-link>
            <router-link to="/#contact" class="a m-3">{{ $t('menu.contact') }}</router-link>
            <div class="d-flex align-items-center m-3 lang">
              <br />
              <a v-if="$i18n.locale !== 'pl'" v-on:click="changeLanguage('pl')">PL</a>
              <strong v-if="$i18n.locale === 'pl'">PL</strong>
              &nbsp;|&nbsp;
              <a v-if="$i18n.locale !== 'en'" v-on:click="changeLanguage('en')">EN</a>
              <strong v-if="$i18n.locale === 'en'">EN</strong>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <!-- <div id="loader"></div> -->
  <main>
    <router-view></router-view>
    <!-- <div class="loader"></div>  -->
  </main>
  <footer>
    <div class="footer">
      <p class="text-center">&copy; 2024 | <a href="https://kamilknap.pl/">Kamil Knap</a></p>
    </div>
  </footer>
</template>


<script>

export default {
  data() {
    return {
      openProjects: false,
    }
  },
  components: {
  },
  methods: {
    goToUrl(tourl){
      this.$router.push({path: tourl})
    },
    changeLanguage(lang) {
      localStorage.setItem("language", lang);
      this.$i18n.locale = lang;
    },
    showProjects(){
      let el = document.querySelector('.insideProjects');
      el.classList.toggle('toShow')
    },
    closeMenu(){
      const element = document.getElementById('primaryBurgerMenu');
      element.checked = false;
    }
  },
  mounted() { },
};
</script>
<style lang="scss" scoped>
.footer{
  background-color: #000;
  border-top: 1px solid rgb(53, 53, 53);
}

.footer p{
  font-weight: bold;
  color: #ffff;
  padding: 10px;
  margin: auto;
}
.fillArrow {
  color: #dec512;
  padding: 10px;
}

.listOfProjects {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}

.insideProjects {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 35px;
  margin-top: -25px;
  margin-bottom: 20px;
  animation-name: hide;
  animation-duration: .3s;
  animation-fill-mode: both;
  
}

.toShow{
  animation-name: show;
  animation-fill-mode: both;
  animation-duration: .3s;
}

@keyframes show {
  0% {
    max-height: 0px; 
    opacity: 0;
  }

  100% {
    max-height: 155px; 
    opacity: 1;
  }
}


@keyframes hide {
  0% {
    max-height: 155px; opacity: 1;
  }
  100% {
    max-height: 0px;
    opacity: 0;
    display: none;
  }
}

.f18 {
  font-size: 18px !important;
  border-left: 1px solid #dec512;
  padding: 8px !important;
  margin: 5px 0;
}




//////////////
/// 
/// 
.loader {
  width: 108px;
  height: 60px;
  color: #269af2;
  --c: radial-gradient(farthest-side,currentColor 96%,#0000);
  background: 
    var(--c) 100% 100% /30% 60%,
    var(--c) 70%  0    /50% 100%,
    var(--c) 0    100% /36% 68%,
    var(--c) 27%  18%  /26% 40%,
    linear-gradient(currentColor 0 0) bottom/67% 58%;
  background-repeat: no-repeat;
  position: relative;
}
.loader:after {
  top: 100px;
  left: 800px;
  content: "";
  position: absolute;
  inset: 0;
  background: inherit;
  opacity: 0.4;
  animation: l7 1s infinite;
}
@keyframes l7 {
  to {transform:scale(1.8);opacity:0}
}
</style>
